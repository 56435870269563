import { gql } from '@apollo/client'

export default gql`
  query shops($first: Int, $where: ShopsFilter) {
    shops(first: $first, where: $where) {
      edges {
        node {
          id
          active
          name
          description
          address {
            street
            city
            postCode
          }
          images {
            uri
          }
        }
      }
    }
  }
`
